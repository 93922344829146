import React,{useState, useContext, useEffect} from "react";
import ic_mail from "../assets/images/ic_mail.svg";
import { AppContext } from "../context/user/AppContext";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import ApiConfig from "../api/ApiConfig";
import { PostCallWithErrorResponse } from "../api/ApiServices";
import LoadingBar from "react-top-loading-bar";


const ContactUs = () => {
  const { mainClass } = useContext(AppContext);
  const [state, setState] = useState({ text: "" })
  const [subject, setSubject] = useState({ subject_text: "" })
  const navigate = useNavigate();
  const { customerData } = useContext(AppContext)
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    setProgress(100)
  }, [])


  const handleSubmit = () => {
    setProgress(50)
    PostCallWithErrorResponse(ApiConfig.SHIPPER_CONTACT_US_API,
      { ...state, ...customerData, ...subject }
    )
      .then((data) => {
        if (data.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setProgress(100)
        if (data.json.result) {
          swal({
            title: "sucess",
            text: data.json.message,
            icon: "success",
            button: true,
          }).then(() => {
            navigate('/Dashboard')
          })


        }

      })
      .catch((error) => {
        console.log("api response", error);

      });

  }


  return (
    <>
   <LoadingBar
        color="red"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
    <main className={`main-body-contactUs  ${mainClass}`} id="cxMain" >
      <div className="contactUs" >
        <div className="contact-us ">
          {/* contact left section */}
          <section className="left-section-contact  w-100 p-2">
            <h3 className="left-heading">Abay Logistics Plc</h3>
            <p className="left-paragraph">
            Welcome to abay Logistitic Shipper Platform. 
            If you have any issue during registration/login, please feel free to 
            contact us with phone number:- +251 945 434343
            </p>
            <div className="email">
            <img src={ic_mail} alt="" /> <span className="email-contact"> abaylogistics@gmail.com</span>
            </div>
            
          </section>
          {/* contact right section */}
          <section className="right-section-contact p-2 w-100">
            <h3 className="get-in-touch">Get In Touch With Us</h3>
            {/* <div className="form-group mt-4">
              <label htmlFor="exampleInputEmail1">Email</label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
            </div> */}
            <div className="form-group mt-4">
              <label htmlFor="exampleInputEmail1">Subject</label>
              <input
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={subject.subject_text}
                onChange={(e) => setSubject({ ...subject, subject_text: e.target.value })}
              />
            </div> 

              <div className="form-group mt-4">
                <label htmlFor="exampleFormControlTextarea1">Message</label>
                <textarea
                  value={state.text}
                  onChange={(e) => setState({ ...state, text: e.target.value })}
                  className="form-control text-area"
                  id="exampleFormControlTextarea1"
                  rows="3"
                ></textarea>
              </div>
              <div className="button mt-4">
                <a href="#" onClick={handleSubmit} className="continue-btn">Continue</a>
              </div>
          </section>
        </div>
      </div>
    </main>
  


    

    </>
  );
};

export default ContactUs;

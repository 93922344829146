
import { Country } from 'country-state-city';
import Select from 'react-select'
export default function CountrySelect(props) {
    const customStyles = {
        input: (provided, state) => ({
            ...provided,
            gridTemplateColumns: '0px 100%'
        }),
    };
    const { setCountry, country } = props
    let options = Country.getAllCountries().map(country => {
        return ({ value: country.name, label: country.name, isoCode: country.isoCode })
    })
    return (
        <div className="form-group mt-4">
            <label htmlFor="exampleInputEmail1">Country</label>

            {/* <input list="brow" id="countryCode" className="form-control w-100" onChange={(e) => {
                let value = options.filter((country,) => country.value === e.target.value)[0];
                setCountry(value)
            }} defaultValue={country} />
            <datalist id="brow" name={"service-city"} style={{ color: "red" }}>
                {options.map((codes, index) => {
                    return (< option key={index + codes.value} value={codes.value} label={codes.label} />)
                })}
            </datalist> */}


            <Select options={options} onChange={setCountry}  {...props} autosize={true} styles={customStyles}
                className="react-select-container"
                value={
                    options.filter(option =>
                        option.label === country)
                } />
        </div>
    )
}

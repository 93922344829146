import "./assets/css/main.scss";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Login from "./sharedComponent/Login";
import "./assets/css/style.scss";
import Dashboard from "./sharedComponent/Dashboard";
import GoodsDetails from "./pages/Transporter/GoodsDetails";
import FAQ from "./sharedComponent/FAQ";
import ContactUs from "./sharedComponent/ContactUs";
import TermsAndConditions from "./sharedComponent/TermsAndConditions";
import Registration from "./sharedComponent/Registration";
import RegistrationSecond from "./sharedComponent/RegistrationSecond";
import Notifications from "./sharedComponent/Notifications";
import AbhayHeader from "./sharedComponent/AbhayHeader";
import Sidebar from "./sharedComponent/Sidebar";
import CompleteFreights from "./pages/Freights/CompleteFreights";
import OngoingFreights from "./pages/Freights/OngoingFreights";
import UpcomingFreights from "./pages/Freights/UpcomingFreights";
import { Reports } from "./pages/Transporter/Reports";
import Rating from "./pages/Transporter/Rating";
import Profile from "./pages/Transporter/Profile";
import GPS_Shipper from "./pages/liveTracking/GPS_Shipper";
import OrderConfirmation from "./pages/DirectOrder/OrderConfirmation";
import FreightsHeaders from "./pages/Freights/Freightsheader";
import ChangeDriver from "./pages/Transporter/ChangeDriver";
import Contracts from "./pages/Freights/Contracts";
import ViewDetails from "./pages/liveTracking/ViewDetails_Goods_Vehicle";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import OfferGoodsList from "./pages/FreightOffer/OfferGoodsList";
import AddOfferGoods from "./pages/FreightOffer/AddOfferGoods";
import DetailsOfferGoods from "./pages/FreightOffer/DetailsOfferGoods";
import VehicleOffers from "./pages/DirectOrder/VehicleOffers";
import OrderConfirmationDetails from "./pages/DirectOrder/OrderConfirmationDetails";
import OnlineVehicleOffers from "./pages/OnlineAuction/OnlineVehicleOffers";
import OnlineOrderConfirmation from "./pages/OnlineAuction/OnlineOrderConfirmation";
import { VehicleDetailsList } from "./pages/OnlineAuction/VehicleDetailsList";
import SelectTransporter from "./pages/OnlineAuction/SelectTransporter";
import AuctionDetails from "./pages/OnlineAuction/AuctionDetails";
import { useState, useEffect, useContext } from "react";
import ShipperRatesImport from "./Rates/ShipperRatesImport";
import ShipperRatesExport from "./Rates/ShipperRatesExport";
import TransporterRates from "./Rates/TransporterRatesChart";
import ForgetPassword from "./sharedComponent/ForgetPassword";
import ResetPassword from "./sharedComponent/ResetPassword";
import { postWithAuthCallWithErrorResponse } from "./api/ApiServices";
import ApiConfig from "./api/ApiConfig";
import { AppContext } from "./context/user/AppContext";
import EditProfile from "./sharedComponent/EditProfile";
import ChangePassword from "./sharedComponent/ChangePassword";
import PageNotFound from "./sharedComponent/PageNotFound";
import ServerError from "./sharedComponent/ServerError";

function App() {
  const location = useLocation().pathname;
  const navigate = useNavigate();
  const { customerData, profileDetails, setProfileDetails } =
    useContext(AppContext);

  const [logedIn, setLoggedIn] = useState(
    localStorage.getItem("logedIn") ? localStorage.getItem("logedIn") : false
  );
  useEffect(() => {
    if (!logedIn) {
      if (
        location.includes("Registration") ||
        location.includes("RegistrationSecond") ||
        location.includes("ForgetPassword") ||
        location.includes("ResetPassword") 
      ) {
        navigate(location);
      }
      navigate("/");
    } else navigate("dashboard");
  }, [logedIn]);

  useEffect(() => {
    if (!logedIn) {
      let pathvariable = window.location.pathname.toString();
      if (
        location === ("/Registration") ||
        location === ("/RegistrationSecond") ||
        location === ("/ForgetPassword") ||
        location === ("/ResetPassword") ||
        location === ("/ServerError")
      ) {
        navigate(location);
      } else if (
        pathvariable === "/Registration" ||
        pathvariable === ("/RegistrationSecond") ||
        pathvariable === ("/ForgetPassword") ||
        pathvariable === ("/ResetPassword") ||
        pathvariable === ("/ServerError")
      ) {
        navigate(location);
      }else navigate("/");
    } else {
      navigate(location);
    }
  }, [location]);
  const [loading, setLoading] = useState(false);
  const profile_pic =
    profileDetails.profile &&
    profileDetails.profile.basic_info &&
    profileDetails.profile.basic_info.profile_pic;
    const getProfileDetails = () => {
      setLoading(true);
      
      postWithAuthCallWithErrorResponse(
        ApiConfig.PROFILE,
      JSON.stringify({
        ...customerData,
      })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      if (res.json.result) setProfileDetails(res.json);
      setLoading(false);
    });
  };
  
  useEffect(() => {
    getProfileDetails();
  }, []);
  return (
    <div className="App">
      {location !== "/" && location !== "Login" && (
        <>
          {!(
            window.location.pathname === "/Registration" ||
            window.location.pathname === "/RegistrationSecond" ||
            window.location.pathname === "/ForgetPassword" ||
            window.location.pathname === "/ResetPassword" ||
            window.location.pathname === "/ServerError"
          ) ? (
            <>
              {logedIn ? (<><Sidebar setLoggedIn={setLoggedIn} /><AbhayHeader setLoggedIn={setLoggedIn} /></> )
                : null }
              
              
            </>
          ) : (
            <></>
          )}
        </>
      )}
      <Routes>
        {/* <Route path="/" element={<GetStarted />} /> */}
        <Route
          path={"/" || "/Login"}
          element={<Login setLoggedIn={setLoggedIn} />}
        />
        <Route path="Dashboard" element={<Dashboard />} />
        <Route path="GoodsDetails" element={<GoodsDetails />} />
        <Route path="FAQ" element={<FAQ />} />
        <Route path="ContactUs" element={<ContactUs />} />
        <Route path="TermsAndConditions" element={<TermsAndConditions />} />
        <Route path="ShipperRatesImport" element={<ShipperRatesImport />} />
        <Route path="ShipperRatesExport" element={<ShipperRatesExport />} />
        <Route path="TransporterRates" element={<TransporterRates />} />
        <Route path="Notifications" element={<Notifications />} />
        <Route path="AbhayHeader" element={<AbhayHeader />} />
        <Route path="CompleteFreights" element={<CompleteFreights />} />
        <Route path="OngoingFreights" element={<OngoingFreights />} />
        <Route path="UpcomingFreights" element={<UpcomingFreights />} />
        <Route path="OrderConfirmation" element={<OrderConfirmation />} />
        <Route path="Reports" element={<Reports />} />
        <Route path="AuctionDetails" element={<AuctionDetails />} />
        <Route path="Registration" element={<Registration />} />
        <Route path="RegistrationSecond" element={<RegistrationSecond />} />
        <Route path="ForgetPassword" element={<ForgetPassword />} />
        <Route path="ResetPassword" element={<ResetPassword />} />
        <Route path="Rating" element={<Rating />} />
        <Route path="Profile" element={<Profile />} />
        <Route path="GPS_Shipper" element={<GPS_Shipper />} />
        <Route path="FreightsHeaders" element={<FreightsHeaders />} />
        <Route path="ChangeDriver" element={<ChangeDriver />} />
        <Route path="Contracts" element={<Contracts />} />
        <Route path="ViewDetails" element={<ViewDetails />} />
        <Route path="OfferGoodsList" element={<OfferGoodsList />} />
        <Route path="AddOfferGoods" element={<AddOfferGoods />} />
        <Route path="DetailsOfferGoods/:id" element={<DetailsOfferGoods />} />
        <Route path="VehicleOffers" element={<VehicleOffers />} />
        <Route path="OrderConfirmation" element={<OrderConfirmation />} />
        <Route path="EditProfile" element={<EditProfile />} />
        <Route
          path="OrderConfirmationDetails"
          element={<OrderConfirmationDetails />}
        />
        <Route path="OnlineVehicleOffers" element={<OnlineVehicleOffers />} />
        <Route
          path="OnlineOrderConfirmation"
          element={<OnlineOrderConfirmation />}
        />
        <Route path="VehicleDetailsList" element={<VehicleDetailsList />} />
        <Route path="SelectTransporter" element={<SelectTransporter />} />
        <Route path="AuctionDetails" element={<AuctionDetails />} />
        <Route path="ChangePassword" element={<ChangePassword />} />
        <Route path="ServerError"  element={<ServerError/> }/>
        <Route path="*"  element={logedIn ? <PageNotFound/> : <Login setLoggedIn={setLoggedIn} /> } />
      </Routes>
    </div>
  );
}

export default App;

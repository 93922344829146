import React,{useContext} from "react";
import { Link } from "react-router-dom";
import AbhayHeader from "../../sharedComponent/AbhayHeader";
import Sidebar from "../../sharedComponent/Sidebar";
import CompleteFreights from "./CompleteFreights";
import OngoingFreights from "./OngoingFreights";
import UpcomingFreights from "./UpcomingFreights";
import "../../assets/css/Freightsheader.scss";
import { AppContext } from "../../context/user/AppContext";

// import '../../assets/css/VehicleDriverDetailsHeader.scss'

const FreightsHeaders = () => {
  const { mainClassB } = useContext(AppContext);

  return (
    <main>
      <div className={`Freights-body ${mainClassB}`} id="cxMainB">
        <ul
          className="nav nav-pills mb-1 "
          id="pills-tab"
          role="tablist"
         
        >
          <li className="nav-item" role="presentation">
            <Link
              to="/OngoingFreights"
              className="nav-link tab-link active"
              id="pills-OngoingFreights-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-OngoingFreights"
              type="button"
              role="tab"
              aria-controls="pills-OngoingFreights"
              aria-selected=""
            >
              Ongoing Freights
            </Link>
          </li>
          <li className="nav-item" role="presentation">
            <Link
              to="/UpcomingFreights"
              className="nav-link tab-link"
              id="pills-UpcomingFreights-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-UpcomingFreights"
              type="button"
              role="tab"
              aria-controls="pills-UpcomingFreights"
              aria-selected=""
            >
              Upcoming Freights
            </Link>
          </li>
          <li className="nav-item" role="presentation">
            <Link
              to="/CompleteFreights"
              className="nav-link tab-link"
              id="pills-Completed-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-Completed"
              type="button"
              role="tab"
              aria-controls="pills-Completed"
              aria-selected=""
            >
              Completed
            </Link>
          </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-OngoingFreights"
            role="tabpanel"
            aria-labelledby="pills-OngoingFreights-tab"
          >
            <OngoingFreights />
          </div>

          <div
            className="tab-pane fade"
            id="pills-UpcomingFreights"
            role="tabpanel"
            aria-labelledby="pills-UpcomingFreights-tab"
          >
            <UpcomingFreights />
          </div>
          <div
            className="tab-pane fade"
            id="pills-Completed"
            role="tabpanel"
            aria-labelledby="pills-Completed-tab"
          >
            <CompleteFreights />
          </div>

          <div
            className="tab-pane fade show active"
            id="pills-Completed"
            role="tabpanel"
            aria-labelledby="pills-Completed-tab"
          ></div>
        </div>
      </div>
    </main>
  );
};

export default FreightsHeaders;

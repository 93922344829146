import React, { useState, useContext, useEffect } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import { Link, NavLink } from "react-router-dom";
import ic_trucks from "../assets/images/Dashboard/ic_vehicle_offers.svg";
import ic_drivers from "../assets/images/Dashboard/ic_freights.svg";
import ic_contracts_1 from "../assets/images/Dashboard/ic_payment_methods.svg";
import ic_contracts from "../assets/images/Dashboard/ic_reports.png";
import ic_call from "../assets/images/Dashboard/ic_call.svg";
import user_1 from "../assets/images/Dashboard/user_1.svg";
import banner_graphic from "../assets/images/Dashboard/shipper_banner_image.svg";
import { AppContext } from "../context/user/AppContext";
import { postWithAuthCallWithErrorResponse } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import Loader from "../sharedComponent/Loader";
import Chart from "react-apexcharts";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
} from "chart.js";
ChartJS.register(
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler
);

const Dashboard = () => {
  const [show, setShow] = useState(true);
  const { customerData, mainClass, profileDetails } = useContext(AppContext);
  const [rateChartList, setRateChartList] = useState([]);
  const [dates, setDates] = useState({
    startDate: "",
    endDate: "",
  });
  const [dashboardDetails, setDashboardDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [ongoingFreightsDetails, setOngoingFreightsDetails] = useState([]);
  const [tariffExportList, setTariffExportList] = useState([]);
  const [tariffImprotList, setTariffImportList] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [graphDetails, setGraphDetails] = useState({
    options: {
      chart: {
        id: "apexchart-example",
      },
      xaxis: {
        categories: [],
      },
    },
    series: [
      {
        name: "series-1",
        data: [],
      },
    ],
    colors: ["#c22e37"],
  });

  const personInfo =
    profileDetails.profile && profileDetails.profile.contact_person;

  useEffect(() => {
    getOngoingFreightsDetails();
    RateChartList();
    TarrifExportList();
    TarrifImportList();
  }, []);

  useEffect(() => {
    getDashboardDetails();
  }, [dates]);
  const getDashboardDetails = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.DASHBOARD_DETAILS,
      JSON.stringify({
        ...customerData,
        from_date: dates.startDate,
        to_date: dates.endDate,
      })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      if (res.json.result) setDashboardDetails(res.json);
      setGraphDetails({
        ...graphDetails,
        options: {
          ...graphDetails.options,
          xaxis: {
            categories: res.json.graph_details.graph.map((data) => data.date),
          },
        },

        series: [
          {
            name: "freights",
            data: res.json.graph_details.graph.map((data) => {
              return data.freights;
            }),
          },
        ],
      });
    });
  };

  const getOngoingFreightsDetails = () => {
    setLoading(true);

    postWithAuthCallWithErrorResponse(
      ApiConfig.ONGOING_FRIGHT,
      JSON.stringify({
        ...customerData,
      })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      setLoading(false);
      if (res.json.result) setOngoingFreightsDetails(res.json.load_list);
    });
  };

  const RateChartList = () => {
    setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.RATE_CHART_LIST,
      JSON.stringify({ ...customerData })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      setRateChartList(res.json.rate_chart_list);
      setLoading(false);
    });
  };
  const TarrifExportList = () => {
    setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.TARRIF_EXPORT_LIST,
      JSON.stringify({ ...customerData })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      setTariffExportList(res.json.tariff_export_list);
      setLoading(false);
    });
  };
  const TarrifImportList = () => {
    setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.TARRIF_IMPORT_LIST,
      JSON.stringify({ ...customerData })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      setTariffImportList(res.json.tariff_import_list);
      setLoading(false);
    });
  };

  return (
    <>
      <main
        className={`main-section-dashboard scroll-style ${mainClass} `}
        // className="main-section-dashboard scroll-style cx-active-sidebar"
        id="cxMain"
      >
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <section className="container-xxl">
            <div className="row">
              <div className="col-lg-12">
                <div className="dashboard-content">
                  <div className="row">
                    <div className="col-lg-12">
                      <section className="banner-dashboard">
                        <div className="row">
                          <div className="col-lg-7 col-sm-7 banner-left">
                            <span>Welcome back,</span>
                            <h3 className="heading-banner">
                              {personInfo && personInfo.name}
                            </h3>
                            <ul className="paragarph-banner">
                              <NavLink to="/OfferGoodsList">
                                <li>Shipper has to send offer load to admin</li>
                              </NavLink>
                              <NavLink to="/VehicleOffers">
                                <li>
                                  Shipper can accept or reject offer vehicle
                                </li>
                              </NavLink>
                              <NavLink to="/OrderConfirmation">
                                <li>Shipper can view Order Confirmation</li>
                              </NavLink>
                            </ul>
                            <div>
                              <Link
                                to="/OfferGoodsList"
                                className="get-start-btn"
                              >
                                Get Started With Offer Loads
                              </Link>
                            </div>
                          </div>
                          <div className="col-lg-5 col-sm-5 banner-right">
                            <img
                              className="banner-img"
                              src={banner_graphic}
                              alt=""
                            />
                          </div>
                        </div>
                      </section>
                      <div className="dashboard-card">
                        <div className="row">
                          {/* left card */}
                          <div className="col-lg-6 ">
                            <div className=" card left-card-section mt-4 card-style">
                              <div className="graph-chart ">
                                <div className="card-body">
                                  <div className="top-content d-flex justify-content-between hr-line pb-2 mb-2">
                                    <div className="top-left"></div>
                                    <div className="top-right d-flex">
                                      <p className="m-0 mt-2 me-2">From</p>
                                      <input
                                        className="form-control"
                                        type="date"
                                        value={dates.startDate}
                                        onChange={(e) => {
                                          // setErrMsg({ ...errMsg, username: "" });
                                          setDates({
                                            ...dates,
                                            startDate: e.target.value,
                                          });
                                        }}
                                      />
                                      <p className="ms-2 mt-2 me-2">to</p>
                                      <input
                                        className="form-control"
                                        type="date"
                                        value={dates.endDate}
                                        onChange={(e) => {
                                          // setErrMsg({ ...errMsg, username: "" });
                                          setDates({
                                            ...dates,
                                            endDate: e.target.value,
                                          });
                                        }}
                                      />
                                      {/* <DateRangePicker placeholder="Select Date Range" /> */}
                                    </div>
                                  </div>

                                  <div className="middel-content chart">
                                    <div className="row">
                                      <div className="col-lg-12">
                                        <Chart
                                          options={graphDetails.options}
                                          series={graphDetails.series}
                                          type="line"
                                          height={200}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <br />
                                  {/* <div className="middel-content chart">
                                    <div className="row">
                                      <div className="col-lg-12">
                                        <Line data={data}>Hello</Line>
                                      </div>
                                    </div>
                                  </div> */}
                                  <div className="bottom-content d-flex justify-content-between">
                                    <div className="left">
                                      <h5 className="left-count">
                                        {graphDetails &&
                                        graphDetails.total_freights
                                          ? graphDetails.total_freights
                                          : 0}
                                      </h5>
                                      <label>Total freights</label>
                                    </div>
                                    <div className="center">
                                      <h5 className="left-count">
                                        {graphDetails &&
                                        graphDetails.this_week_count
                                          ? graphDetails.this_week_count
                                          : 0}
                                      </h5>
                                      <label>Delivered This week</label>
                                    </div>
                                    <div className="right">
                                      <h5 className="left-count">
                                        {graphDetails &&
                                        graphDetails.total_revenue
                                          ? graphDetails.total_revenue
                                          : 0}
                                      </h5>
                                      <label>Total revenue</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="single_count mt-4">
                                  <div className="card card-style">
                                    <div
                                      className="card-body d-flex justify-content-between"
                                      style={{
                                        borderBottom: "1px solid #EDF4FC",
                                      }}
                                    >
                                      <div>
                                        <h4 className="purple">
                                          {dashboardDetails.vehicle_offers}
                                        </h4>
                                        <p className="count-name">
                                          Vehicle Offers
                                        </p>
                                      </div>

                                      <div>
                                        <img src={ic_trucks} alt="" />
                                      </div>
                                    </div>

                                    <div className="card-body bottom d-flex justify-content-between">
                                      <div className="left"></div>
                                      <div className="right view-all-card-1">
                                        <Link to="/VehicleOffers">
                                          View All
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="single_count mt-4">
                                  <div className="card card-style">
                                    <div
                                      className="card-body d-flex justify-content-between"
                                      style={{
                                        borderBottom: "1px solid #EDF4FC",
                                      }}
                                    >
                                      <div>
                                        <h4 className="orange">
                                          {dashboardDetails.frieghts}
                                        </h4>
                                        <p className="count-name">Freights</p>
                                      </div>

                                      <div>
                                        <img src={ic_drivers} alt="" />
                                      </div>
                                    </div>

                                    <div className="card-body bottom d-flex justify-content-between">
                                      <div className="left">
                                        <label className="active ">
                                          Active
                                        </label>
                                        <span
                                          className="active-count "
                                          style={{
                                            marginLeft: "10px",
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {dashboardDetails.active_freights}
                                        </span>
                                      </div>
                                      <div className="right view-all-card-2">
                                        <Link to="/FreightsHeaders">
                                          View All
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="single_count mt-4">
                                  <div className="card card-style">
                                    <div
                                      className="card-body d-flex justify-content-between"
                                      style={{
                                        borderBottom: "1px solid #EDF4FC",
                                      }}
                                    >
                                      <div>
                                        <h4 className="green">
                                          {dashboardDetails.reports}
                                        </h4>
                                        <p className="count-name">Reports</p>
                                      </div>

                                      <div>
                                        <img src={ic_contracts} alt="" />
                                      </div>
                                    </div>

                                    <div className="card-body bottom d-flex justify-content-between">
                                      <div className="left"></div>
                                      <div className="right view-all-card-3">
                                        <Link to="/Reports">View All</Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="single_count mt-4">
                                  <div className="card card-style">
                                    <div
                                      className="card-body d-flex justify-content-between"
                                      style={{
                                        borderBottom: "1px solid #EDF4FC",
                                      }}
                                    >
                                      <div>
                                        <h4 className="blue">
                                          {dashboardDetails.payment_methods}
                                        </h4>
                                        <p className="count-name">
                                          Payment Methods
                                        </p>
                                      </div>

                                      <div>
                                        <img src={ic_contracts_1} alt="" />
                                      </div>
                                    </div>

                                    <div className="card-body bottom d-flex justify-content-between">
                                      <div className="left">
                                        <label className="active">Active</label>
                                        <span
                                          className="active-count "
                                          style={{
                                            marginLeft: "10px",
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {
                                            dashboardDetails.payment_methods_active
                                          }
                                        </span>
                                      </div>
                                      <div className="right view-all-card-4">
                                        <Link to="#">View All</Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Ongoing and Notification card */}
                      <div className="details-card">
                        <div className="row">
                          {/* left card */}
                          {/* <div className="col-12">
                            <div className="card card-style left-card-dashboard scroll-style mt-4">
                              <div className="card-body">
                                <div
                                  className="card_title d-flex justify-content-between pb-2"
                                  style={{ borderBottom: "1px solid #F6F2EF" }}
                                >
                                  <h4 className="ongoing-heading common-heading">
                                    Ongoing Freights
                                  </h4>
                                  <div className="right-top d-flex justify-content-between ">
                                    <div>
                                      <Link
                                        to="/GPS_Shipper"
                                        className="blue_link m-2"
                                        style={{ fontSize: "16px" }}
                                      >
                                        Map View
                                      </Link>
                                    </div>
                                  </div>
                                </div>

                                <div className="user_block mt-4">
                                  {ongoingFreightsDetails.map(
                                    (ongoingFreight, index) => {
                                      return (
                                        <div
                                          style={{
                                            borderBottom: "1px solid #F6F2EF",
                                          }}
                                          className="pb-2 mb-3"
                                          key={"ongoingFreight" + index}
                                        >
                                          <div className="d-flex justify-content-between Ongoing-Freights align-items-start mb-2 ">
                                            <div className="d-flex align-items-start">
                                              <img
                                                src={user_1}
                                                className="me-2"
                                                alt="user"
                                                height="32"
                                                width="32"
                                              />
                                              <div className="ms-3">
                                                <span className="name">
                                                  {
                                                    ongoingFreight.trip_reference_no
                                                  }
                                                </span>
                                                <p className="location">
                                                  {
                                                    ongoingFreight.trip_start_country
                                                  }{" "}
                                                  {
                                                    ongoingFreight.trip_start_city
                                                  }{" "}
                                                  -{" "}
                                                  {
                                                    ongoingFreight.trip_end_country
                                                  }{" "}
                                                  {ongoingFreight.trip_end_city}
                                                </p>
                                              </div>
                                            </div>
                                            <div>
                                              <Link
                                                to="/ViewDetails"
                                                state={ongoingFreight}
                                                className="blue_link view-details"
                                              >
                                                View Details
                                              </Link>
                                              <Link to="#" className="ms-2">
                                                <img src={ic_call} alt="" />
                                              </Link>
                                            </div>
                                          </div>
                                          <div className="row ms-5 user-status">
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                              <label htmlFor="">
                                                Started On
                                              </label>
                                              <p>
                                                {ongoingFreight.trip_start_date}
                                              </p>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                              <label htmlFor="">
                                                Estimated Delivery Date
                                              </label>
                                              <p>
                                                {
                                                  ongoingFreight.estimated_arrival_date
                                                }
                                              </p>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                              <label htmlFor="">Status</label>
                                              <p>Loaded - On the way</p>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </div>
                          </div> */}
                          {/* right card */}
                          {/* <div className="col-lg-6">
                          <div className=" card-style right-card-dashboard scroll-style mt-4">
                            <div className="card-body p-4">
                              <div className="card_title d-flex justify-content-between ">
                                <h4 className="ongoing-heading common-heading">
                                  Recent Activities
                                </h4>
                                <a href="#" className="blue_link ">
                                  View All
                                </a>
                              </div>
                              <hr />
                              <div className="notification-all ">
                                <div className=" mb-2">
                                  <h5 className="notification-heading">
                                    Lorem Ipsum{" "}
                                  </h5>
                                  <p className="notification-paragraph">
                                    Lorem ipsum dolor sit amet, consetetur
                                    sadipscing elitr, sed diam nonumy eirmod
                                    tempor invidunt ut labore et dolore magna
                                    aliquyam erat, sed diam voluptua. At vero
                                    eos et accusam et justo duo dolores et ea
                                    rebum.
                                  </p>
                                  <p className="date-time text-end">
                                    25-03-2022, 10:30 AM
                                  </p>
                                </div>
                                <hr />
                                <div className=" mb-2">
                                  <h5 className="notification-heading">
                                    Lorem Ipsum{" "}
                                  </h5>
                                  <p className="notification-paragraph">
                                    Lorem ipsum dolor sit amet, consetetur
                                    sadipscing elitr, sed diam nonumy eirmod
                                    tempor invidunt ut labore et dolore magna
                                    aliquyam erat, sed diam voluptua. At vero
                                    eos et accusam et justo duo dolores et ea
                                    rebum.
                                  </p>
                                  <p className="date-time text-end">
                                    25-03-2022, 10:30 AM
                                  </p>
                                </div>
                                <hr />
                                <div className=" mb-2">
                                  <h5 className="notification-heading">
                                    Lorem Ipsum{" "}
                                  </h5>
                                  <p className="notification-paragraph">
                                    Lorem ipsum dolor sit amet, consetetur
                                    sadipscing elitr, sed diam nonumy eirmod
                                    tempor invidunt ut labore et dolore magna
                                    aliquyam erat, sed diam voluptua. At vero
                                    eos et accusam et justo duo dolores et ea
                                    rebum.
                                  </p>
                                  <p className="date-time text-end">
                                    25-03-2022, 10:30 AM
                                  </p>
                                </div>
                                <hr />
                                <div className=" mb-2">
                                  <h5 className="notification-heading">
                                    Lorem Ipsum{" "}
                                  </h5>
                                  <p className="notification-paragraph">
                                    Lorem ipsum dolor sit amet, consetetur
                                    sadipscing elitr, sed diam nonumy eirmod
                                    tempor invidunt ut labore et dolore magna
                                    aliquyam erat, sed diam voluptua. At vero
                                    eos et accusam et justo duo dolores et ea
                                    rebum.
                                  </p>
                                  <p className="date-time text-end">
                                    25-03-2022, 10:30 AM
                                  </p>
                                </div>
                                <hr />
                                <div className=" mb-2">
                                  <h5 className="notification-heading">
                                    Lorem Ipsum{" "}
                                  </h5>
                                  <p className="notification-paragraph">
                                    Lorem ipsum dolor sit amet, consetetur
                                    sadipscing elitr, sed diam nonumy eirmod
                                    tempor invidunt ut labore et dolore magna
                                    aliquyam erat, sed diam voluptua. At vero
                                    eos et accusam et justo duo dolores et ea
                                    rebum.
                                  </p>
                                  <p className="date-time text-end">
                                    25-03-2022, 10:30 AM
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        </div>
                      </div>
                      {/* ======================= RATES ================ */}

                      <div className="rates-main">
                        <div className="transporter">
                          <div className="heading-top">
                            <div className="left">
                              Abay Plc Port and Terminal handling Rate
                              chart(Import/Export)
                            </div>
                            <div className="right">
                              <Link to="/TransporterRates">View All</Link>
                            </div>
                          </div>
                          <div className="offerLoadsMainLists">
                            <div
                              className="mainLoadTable"
                              style={{ overflow: "auto" }}
                            >
                              <table
                                className="dataList"
                                style={{ minWidth: "900px" }}
                              >
                                <thead>
                                  <tr>
                                    <th>
                                      <label>S.No.</label>
                                    </th>
                                    <th>
                                      <label>Rate type /Category</label>
                                    </th>
                                    <th>
                                      <label>Currency</label>
                                    </th>
                                    <th>
                                      <label>20 ft(teu)</label>
                                    </th>

                                    <th>
                                      <label>40 ft(feu)</label>
                                    </th>
                                    <th className="">
                                      <label>Break bulk(freight tone)</label>
                                    </th>
                                    <th>
                                      <label>Bulk (tone)</label>
                                    </th>
                                    <th>
                                      <label>RORO(Unit)</label>
                                    </th>
                                    <th>
                                      <label>Other</label>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {rateChartList &&
                                    rateChartList.map((rateListItem, index) => {
                                      return (
                                        <tr
                                          className="Rowbgcolor"
                                          key={"rateListItem" + index}
                                        >
                                          <td>
                                            <label>{index + 1}</label>
                                          </td>
                                          <td>
                                            <label>
                                              {rateListItem.rate_chart_category}
                                            </label>
                                          </td>
                                          <td>
                                            <label>
                                              {rateListItem.currency_code}
                                            </label>
                                          </td>
                                          <td>
                                            <label>
                                              {rateChartList.rate_chart_20ft}
                                            </label>
                                          </td>

                                          <td>
                                            <label>
                                              {rateListItem.rate_chart_40ft}
                                            </label>
                                          </td>

                                          <td>
                                            <label>
                                              {
                                                rateListItem.rate_chart_break_bulk
                                              }
                                            </label>
                                          </td>
                                          <td>
                                            <label>
                                              {rateListItem.rate_chart_bulk}
                                            </label>
                                          </td>
                                          <td>
                                            <label>
                                              {rateListItem.rate_chart_roro}
                                            </label>
                                          </td>
                                          <td>
                                            <label>
                                              {rateListItem.rate_chart_other}
                                            </label>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div className="shipper">
                          <div className="heading-top">
                            <div className="left">
                              Abay Logistics plc. Transporttariff Chart(Import)
                            </div>
                            <div className="right">
                              <Link to="/ShipperRatesImport">View All</Link>
                            </div>
                          </div>
                          <div className="heading-top mt-3">
                            <div className="left">DEPARTURE--Djibouti</div>
                          </div>

                          <div className="offerLoadsMainLists">
                            <div
                              className="mainLoadTable"
                              style={{ overflow: "auto" }}
                            >
                              <table
                                className="dataList"
                                style={{ minWidth: "900px" }}
                              >
                                <thead>
                                  <tr>
                                    <th>
                                      <label>S.No.</label>
                                    </th>
                                    <th>
                                      <label>Destination</label>
                                    </th>
                                    <th>
                                      <label>Km</label>
                                    </th>
                                    <th>
                                      <label>Currency</label>
                                    </th>

                                    <th>
                                      <label>Unit of Measurement</label>
                                    </th>
                                    <th className="">
                                      <label>Tariff (Birr) </label>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {tariffImprotList &&
                                    tariffImprotList.map(
                                      (tariffImprotListData, index) => {
                                        return (
                                          <tr
                                            className="Rowbgcolor"
                                            key={"tariffImprotListData" + index}
                                          >
                                            <td>
                                              <label>{index + 1}</label>
                                            </td>
                                            <td>
                                              <label>
                                                {
                                                  tariffImprotListData.tariff_import_destination
                                                }
                                              </label>
                                            </td>
                                            <td>
                                              <label>
                                                {
                                                  tariffImprotListData.tariff_import_km
                                                }
                                              </label>
                                            </td>
                                            <td>
                                              <label>
                                                {
                                                  tariffImprotListData.currency_code
                                                }
                                              </label>
                                            </td>

                                            <td>
                                              <label>
                                                {tariffImprotListData.unit_name}
                                              </label>
                                            </td>

                                            <td>
                                              <label>
                                                {
                                                  tariffImprotListData.tariff_import_tariff
                                                }
                                              </label>
                                            </td>
                                            <td>
                                              <label></label>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div className="shipper">
                          <div className="heading-top">
                            <div className="left">
                              Abay Logistics plc. Transport tariff Chart(Export)
                            </div>
                            <div className="right">
                              <Link to="/ShipperRatesExport">View All</Link>
                            </div>
                          </div>
                          <div className="heading-top mt-3">
                            <div className="left">DESTINATION--Djibouti</div>
                          </div>

                          <div className="offerLoadsMainLists">
                            <div
                              className="mainLoadTable"
                              style={{ overflow: "auto" }}
                            >
                              <table
                                className="dataList"
                                style={{ minWidth: "900px" }}
                              >
                                <thead>
                                  <tr>
                                    <th>
                                      <label>S.No.</label>
                                    </th>
                                    <th>
                                      <label>Departure</label>
                                    </th>
                                    <th>
                                      <label>Km</label>
                                    </th>
                                    <th>
                                      <label>Currency</label>
                                    </th>

                                    <th>
                                      <label>Unit of Measurement</label>
                                    </th>
                                    <th className="">
                                      <label>Tariff (Birr) </label>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {tariffExportList &&
                                    tariffExportList.map(
                                      (tariffExportListDAta, index) => {
                                        return (
                                          <tr
                                            className="Rowbgcolor"
                                            key={"tariffExportListDAta" + index}
                                          >
                                            <td>
                                              <label>{index + 1}</label>
                                            </td>
                                            <td>
                                              <label>
                                                {
                                                  tariffExportListDAta.tariff_export_destination
                                                }
                                              </label>
                                            </td>
                                            <td>
                                              <label>
                                                {
                                                  tariffExportListDAta.tariff_export_km
                                                }
                                              </label>
                                            </td>
                                            <td>
                                              <label>
                                                {
                                                  tariffExportListDAta.currency_code
                                                }
                                              </label>
                                            </td>

                                            <td>
                                              <label>
                                                {tariffExportListDAta.unit_name}
                                              </label>
                                            </td>

                                            <td>
                                              <label>
                                                {
                                                  tariffExportListDAta.tariff_export_tariff
                                                }
                                              </label>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
    </>
  );
};

export default Dashboard;

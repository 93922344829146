import { Link } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import Abay_logo from "../assets/images/header/Abay_logo.png";
import ic_notification from "../assets/images/header/ic_notification.svg";
import ic_options from "../assets/images//ic_options.svg";
import profile_img from "../assets/images/header/profile_img.png";
import user_2 from "../assets/images/header/ChangeDriver/user_2.svg";
import ic_contact_us from "../assets/images/header/Profile/ic_contact_us.svg";
import right_arrow from "../assets/images/header/Profile/right_arrow.svg";
import ic_contracts from "../assets/images/header/Profile/ic_contracts.svg";
import ic_settings from "../assets/images/header/Profile/ic_settings.svg";
import Privacy from "../assets/images/header/Profile/Privacy.svg";
import ic_legal from "../assets/images/header/Profile/ic_legal.svg";
import ic_logout from "../assets/images/header/Profile/ic_logout.svg";
import ic_profile from "../assets/images/line_user-3.svg";
import dropdown_arrow_blue from "../assets/images/ic_dropdown_arrow.svg";
import ApiConfig from "../api/ApiConfig";
import swal from "sweetalert";

import { postWithAuthCallWithErrorResponse } from "../api/ApiServices";
import Barloader from "./BarLoader";
import { AppContext } from "../context/user/AppContext";
const AbhayHeader = ({ setLoggedIn }) => {
  const [state, setState] = useState(false);
  const [pin, setPin] = useState(false);
  const [btnclose, setBtnClose] = useState(false);
  const [notification, setNotification] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [drop, setDrop] = useState(false);

  const { ApiCalling, profileDetails, customerData, customerDetails } =
    useContext(AppContext);

  const basicInfo = profileDetails.profile && profileDetails.profile.basic_info;
  const personInfo =
    profileDetails.profile && profileDetails.profile.contact_person;
  useEffect(() => {
    getNotification();
    if (ApiCalling) {
      window.scrollTo(0, 0);
    }
  }, [ApiCalling]);
  const getNotification = () => {
    setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.NOTIFICATION,
      JSON.stringify({ ...customerData })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      setLoading(false);
      if (res.json.result) setNotification(res.json.notifications);
    });
  };

  const DeleteNotification = (notification_id) => {
    setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.NOTIFICATION,
      JSON.stringify({ ...customerData, notification_id : notification_id })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      setLoading(false);
      if (res.json.result) setNotification(res.json.notifications);
    });
  };
 
  const logout = (e) => {
    e.preventDefault();
    postWithAuthCallWithErrorResponse(
      ApiConfig.SHIPPER_LOGOUT_API,
      JSON.stringify({ ...customerData })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      if (res.json.result) {
        localStorage.clear();
        setLoggedIn(false);
        swal(res.json.message);   
      }else {
        swal("Error While Logging Out"); 
      }   
    }).catch(e=>{
      swal("Error Occured While Logging Out!"); 
    });
  };

  function handleClick() {
    setState(!state);
  }
  function handleNotif() {
    setPin(!pin);
  }
  function handleClose() {
    setBtnClose(!btnclose);
  }
  const mobileSidebar = () => {};
  return (
    <React.Fragment>
      <header className="ab-header">
        <nav className="navbar navbar-expand-md cx-navbar">
          <div className="container-fluid ab-header-container">
            <Link to="/Dashboard" className="navbar-logo">
              <img
                src={Abay_logo}
                className="img-logo"
                alt="Abay-logo"
                onClick={mobileSidebar}
              />
            </Link>
            <div className="dash">Shipper Dashboard</div>
            <ul className="navbar-nav ms-auto ">
              <li className="nav-item ">
                <a
                  className="nav-link pe-auto"
                  aria-current="page"
                  href="#"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRightNotification"
                  aria-controls="offcanvasRightNotification"
                >
                  <img
                    // onClick={handleNotif}
                    src={ic_notification}
                    alt="notification-icon"
                  />
                  <div className="cx-alert">{notification.length}</div>
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#"
                  className="nav-link pe-auto"
                  aria-current="page"
                  // onClick={handleClick}
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRightAccount"
                  aria-controls="offcanvasRightAccount"
                >
                  <img
                    style={{
                      width: "35px",
                      height: "35px",
                      borderRadius: "50%",
                    }}
                    src={
                      ApiConfig.BASE_URL_FOR_IMAGES +
                      (basicInfo?.profile_pic
                        ? basicInfo?.profile_pic
                        : profile_img)
                    }
                    alt="Profileimg"
                  />
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </header>

      {/* //##-------------------------##
              Change Driver Start
         //##-------------------------## */}

      {/* ---------------------------------------------------------
                                Notification 
      ----------------------------------------------------------- */}

      <section>
        <div className="notification-out">
          <div
            className="Notification-content offcanvas offcanvas-end"
            tabIndex="-1"
            id="offcanvasRightNotification"
            aria-labelledby="offcanvasRightLabelNotification"
          >
            <div className="offcanvas-header justify-content-end">
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>

            <div className=" Notification-header">
              <div
                className="d-flex  justify-content-between"
                style={{ borderBottom: "1px solid #F6F2EF" }}
              >
                <p className="card-heading">Notifications</p>
                <p
                  className="me-2 text-decoration-none"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  <Link to="/Notifications" className="see">
                    See All
                  </Link>
                </p>
              </div>

              <div className="inside mt-2 mb-4">
                {notification.map((notify, index) => {
                  return (
                    <div
                      className="d-flex justify-content-between mb-2"
                      key={"notify" + index}
                      style={{ borderBottom: "1px solid #F6F2EF" }}
                    >
                      <div className=" ">
                        <p>{notify.notification_text}</p>
                        <label>{notify.notification_time}</label>
                      </div>
                      <div className="option-dropdown dropdown ">
                        <a
                          href=""
                          className=""
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img src={ic_options} alt="" />
                        </a>
                        <ul className="dropdown-menu ">
                          <li>
                            <button className="dropdown-item" type="button">
                            <a
                              href=""
                              className=""
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              onClick={(e) => {
                                e.preventDefault();
                                DeleteNotification(notify?.notification_id);
                              }}
                            >
                              Delete{" "}
                              </a>
                              <img
                                src={dropdown_arrow_blue}
                                className="orderConArow"
                                alt=""
                              />
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {ApiCalling && <Barloader id="loading" />}
      </section>

      {/* ----------------------------------------------------------------
                          profile
      ---------------------------------------------------------------
       */}

      <section className="">
        <div className="cx-account-out">
          <div className=" Account-content">
            <div className="Account-header">
              <div
                className="Account offcanvas offcanvas-end"
                tabIndex="-1"
                id="offcanvasRightAccount"
                aria-labelledby="offcanvasRightLabel"
              >
                <div className="offcanvas-header justify-content-end">
                  <button
                    type="button"
                    className="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="insidepro text-center ">
                  <Link to="/profile">
                    <img
                      src={
                        ApiConfig.BASE_URL_FOR_IMAGES +
                        (basicInfo &&
                        basicInfo.profile_pic &&
                        basicInfo.profile_pic
                          ? basicInfo.profile_pic
                          : profile_img)
                      }
                      alt="responsive-image"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    />
                  </Link>
                  <p className="Header-profile-name mt-2" htmlFor="">
                    {personInfo && personInfo.name}
                  </p>
                  <label className="mb-4">
                    {personInfo && personInfo.email}
                  </label>
                </div>

                <ul
                  className="list-container account-list-style"
                  style={{ marginLeft: "2rem" }}
                >
                  <li
                    className="list-unstyled mt-2"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    <Link to="/profile" className="icon_arrow ">
                      <div className="d-flex  promenu">
                        <img src={ic_profile} alt="" />
                        <h5 className="contnt  m-2">Profile</h5>
                      </div>
                      <img className="" src={right_arrow} alt="" />
                    </Link>
                  </li>
                  <li
                    className="list-unstyled mt-2"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    <Link to="/ContactUs" className="icon_arrow ">
                      <div className="d-flex  promenu">
                        <img src={ic_contact_us} alt="" />
                        <h5 className="contnt  m-2">Contact Us </h5>
                      </div>
                      <img className="" src={right_arrow} alt="" />
                    </Link>
                  </li>
                  <li
                    className="list-unstyled  mt-2"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    <Link to="/Contracts" className="icon_arrow">
                      <div className="d-flex promenu">
                        <img className="" src={ic_contracts} alt="" />
                        <h5 className="contnt  m-2">Contracts</h5>
                      </div>
                      <img className=" " src={right_arrow} alt="" />
                    </Link>
                  </li>

                  <li
                    className="list-unstyled  mt-2"
                    // data-bs-dismiss="offcanvas"
                    // aria-label="Close"
                    onClick={() => setDrop(!drop)}
                  >
                    <Link to="#" className="icon_arrow">
                      <div className="d-flex promenu">
                        <img className="" src={ic_settings} alt="" />
                        <h5 className="contnt  m-2">Settings</h5>
                      </div>
                      <img className=" " src={right_arrow} alt="" />
                    </Link>
                  </li>
                  {drop && (
                    <div>
                      <div id="example-collapse-text">
                        <ul style={{ paddingLeft: "0px" }}>
                          <li
                            className="good list-unstyled"
                            onClick={() => setDrop(!drop)}
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                          >
                            <Link
                              // style={({ isActive }) => {
                              //   return { opacity: isActive ? "1" : "0.5" };
                              // }}

                              to="/ChangePassword"
                              className="MGT text-decoration-none text-dark"
                            >
                              {" "}
                              Change Password
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                  <li
                    className="list-unstyled  mt-2"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    <Link to="/TermsAndConditions" className="icon_arrow">
                      <div className="d-flex promenu">
                        <img className="" src={ic_legal} alt="" />
                        <h5 className="contnt  m-2">Terms & Conditions</h5>
                      </div>
                      <img className=" " src={right_arrow} alt="" />
                    </Link>
                  </li>
                  <li
                    className="list-unstyled mt-2"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    <Link to="#" className="icon_arrow">
                      <div className="d-flex promenu">
                        <img className="" src={Privacy} alt="" />
                        <h5 className="contnt  m-2">Privacy Policy</h5>
                      </div>
                      <img className=" " src={right_arrow} alt="" />
                    </Link>
                  </li>
                  <li
                    className="list-unstyled  mt-2"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    <Link to="/" className="icon_arrow" onClick={logout}>
                      <div className="d-flex promenu">
                        <img className="" src={ic_logout} alt="" />
                        <h5 className="contnt  m-2">Logout</h5>
                      </div>
                      <img className=" " src={right_arrow} alt="" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default AbhayHeader;

import React, { useState, useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Skyscrapers from "../../assets/images/Skyscrapers.jpg";
import ic_options from "../../assets/images/ic_call_big.svg";
import star_highlighted from "../../assets/images/vehiclesDetails/star_highlighted.svg";
import star_grey from "../../assets/images/vehiclesDetails/ic_star_grey.svg";
import dropdown_arrow_blue from "../../assets/images/dropdown_arrow_blue.svg";
import user_1 from "../../assets/images/Dashboard/user_2.svg";
import { Modal } from "react-bootstrap";
import "jquery/dist/jquery.min.js";

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { AppContext } from "../../context/user/AppContext";
import ApiConfig from "../../api/ApiConfig";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";

const ViewDetails = () => {
  const goodsData = useLocation().state;
  const goods = useLocation().state;

  const { mainClassB, customerData } = useContext(AppContext);
  const [goodsDetails, setGoodsDetails] = useState({
    ...goodsData,
  });
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ ...goods });
  const [transpoterLists, setTranspoterLists] = useState([]);

  useEffect(() => {
    getGoodsDetails();
    gettranspoterList();
  }, []);
  const getGoodsDetails = () => {
    setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.OFFER_LOAD_DETAILS,
      JSON.stringify({ ...customerData, load_id: goodsDetails.trip_id })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      setLoading(false);
      if (res.json.result) setGoodsDetails(res.json.load_details);
    });
  };

  const gettranspoterList = () => {
    setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.TRANSPOTER_LIST,
      JSON.stringify({
        ...customerData,
        load_id: data.trip_id,
      })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      setLoading(false);
      if (res.json.result) setTranspoterLists(res.json.transporter_list);
    });
  };

  $(document).ready(function () {
    $(".dataList").DataTable();
  });
  return (
    <div>
      <main className="vehicle-popup">
        <div className={`header-body  ${mainClassB}`} id="cxMainB">
          <ul className="nav nav-pills mb-1" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <Link
                to="/VehiclesDetails"
                className="nav-link tab-link active"
                id="pills-goodsdetails-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-goodsdetails"
                type="button"
                role="tab"
                aria-controls="pills-goodsdetails"
                aria-selected="true"
              >
                Goods Details
              </Link>
            </li>
            <li className="nav-item" role="presentation">
              <Link
                to="/VehiclesDetails"
                className="nav-link tab-link "
                id="pills-driverdetails-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-driverdetails"
                type="button"
                role="tab"
                aria-controls="pills-driverdetails"
                aria-selected="true"
                onClick={() => setShow(true)}
              >
                Vehicle Details
              </Link>
            </li>
          </ul>
          <div
            className="tab-content "
            id="pills-tabContent"
            style={{ margin: "20px", overflow: "auto" }}
          >
            {/* Goods details */}

            <div
              className="tab-pane fade show active live-goods-details"
              id="pills-goodsdetails"
              role="tabpanel"
              aria-labelledby="pills-goodsdetails-tab"
            >
              {/* From details section */}
              <section className="from-details">
                <div className="common-body mt-4 p-3">
                  <div className="row">
                    <div className="top-content ">
                      <h5 className="card-heading">From</h5>
                      <hr />
                    </div>
                    <div className="col-lg-3 col-sm-6 ">
                      <label htmlFor="">Estimated Start Date</label>
                      <p className="data">{goodsDetails.trip_start_date}</p>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                      <label htmlFor="">Estimated Start Time</label>
                      <p className="data">{goodsDetails.trip_start_time}</p>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                      <label htmlFor="">Country</label>
                      <p className="data">{goodsDetails.trip_start_country}</p>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                      <label htmlFor="">City</label>
                      <p className="data">{goodsDetails.trip_start_city}</p>
                    </div>
                    <div className="col-12">
                      <label htmlFor="">Address</label>
                      <p className="data">{goodsDetails.trip_start_address}</p>
                    </div>
                  </div>
                </div>
              </section>
              {/* To details section */}
              <section className="to-details">
                <div className="common-body mt-4 p-3">
                  <div className="row">
                    <div className="top-content ">
                      <h5 className="card-heading">To</h5>
                      <hr />
                    </div>
                    <div className="col-lg-3 col-sm-6">
                      <label htmlFor="">Estimated Start Date</label>
                      <p className="data">{goodsDetails.trip_end_date}</p>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                      <label htmlFor="">Estimated Start Time</label>
                      <p className="data">{goodsDetails.trip_end_time}</p>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                      <label htmlFor="">Country</label>
                      <p className="data">{goodsDetails.trip_end_country}</p>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                      <label htmlFor="">City</label>
                      <p className="data">{goodsDetails.trip_end_city}</p>
                    </div>
                    <div className="col-12">
                      <label htmlFor="">Address</label>
                      <p className="data">{goodsDetails.trip_end_address}</p>
                    </div>
                  </div>
                </div>
              </section>
              {/* Goods details */}
              <section className="goods-details mb-4">
                <div className="common-body mt-4 p-3">
                  <div className="row">
                    <div className="top-content ">
                      <h5 className="card-heading">Goods Details</h5>
                      <hr />
                    </div>
                    {/* left section */}
                    <div className="col-lg-6">
                      <div className="image">
                        <div
                          id="carouselExampleControls"
                          className="carousel slide"
                          data-bs-ride="carousel"
                        >
                          {goodsDetails &&
                            goodsDetails.trip_image &&
                            goodsDetails.trip_image.map((img, index) => {
                              return (
                                <div className="carousel-item">
                                  <img
                                    width="515px"
                                    height="300px"
                                    src={
                                      goodsDetails.trip_image
                                        ? ApiConfig.BASE_URL_FOR_IMAGES +
                                          goodsDetails.trip_image
                                        : Skyscrapers
                                    }
                                    className="d-block "
                                    alt="..."
                                  />
                                </div>
                              );
                            })}
                          <button
                            className="carousel-control-prev"
                            type="button"
                            data-bs-target="#carouselExampleControls"
                            data-bs-slide="prev"
                          >
                            <span
                              className="carousel-control-prev-icon"
                              aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Previous</span>
                          </button>
                          <button
                            className="carousel-control-next"
                            type="button"
                            data-bs-target="#carouselExampleControls"
                            data-bs-slide="next"
                          >
                            <span
                              className="carousel-control-next-icon"
                              aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Next</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* right section */}
                    <div className="col-lg-6">
                      <div className="row">
                        <div className="col-lg-4 mt-4 col-sm-6">
                          <label htmlFor="">Reference Number</label>
                          <p className="data">
                            {goodsDetails.trip_reference_no}
                          </p>
                        </div>
                        <div className="col-lg-4 mt-4 col-sm-6">
                          <label htmlFor="">Goods Name</label>
                          <p className="data">My Freight</p>
                        </div>
                        <div className="col-lg-4 mt-4 col-sm-6">
                          <label htmlFor="">Company Name</label>
                          <p className="data">
                            {goodsDetails.trip_company_name}
                          </p>
                        </div>
                        <div className="col-lg-4 mt-4 col-sm-6">
                          <label htmlFor="">Cargo Type</label>
                          <p className="data">{goodsDetails.cargo_type}</p>
                        </div>

                        <div className="col-lg-4 mt-4 col-sm-6">
                          <label htmlFor="">Quantity</label>
                          <p className="data">{goodsDetails.quantity}</p>
                        </div>
                        <div className="col-lg-4 col-sm-6 mt-4">
                          <label htmlFor="">Delivery Type</label>
                          <p className="data">import</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            {/* Vehicle details */}
            <div
              className="tab-pane fade show "
              id="pills-driverdetails"
              role="tabpanel"
              aria-labelledby="pills-driverdetails-tab"
            >
              {transpoterLists &&
                transpoterLists.length &&
                transpoterLists.map((transporter, index) => {
                  return (
                    <Link
                      to="/VehicleDetailsList"
                      className="text-decoration-none"
                      state={data}
                      key={"transporter" + index}
                    >
                      <div className="top-content common-body mt-4 d-flex justify-content-between">
                        <div className="driver-profile-details d-flex align-items-center">
                          <div className="profile-pic-veiw me-3">
                            <img
                              src={
                                transporter.user_profile_pic
                                  ? ApiConfig.BASE_URL_FOR_IMAGES +
                                    transporter.user_profile_pic
                                  : user_1
                              }
                              alt=""
                            />
                          </div>
                          <div className="driver-name">
                            <div className="title-driver">
                              <p className="name-driver-view-details mb-0">
                                {transporter.user_name}
                              </p>
                            </div>
                            <div className="rating">
                              <img src={star_highlighted} alt="" />
                              <img src={star_highlighted} alt="" />

                              <img src={star_highlighted} alt="" />
                              <img src={star_highlighted} alt="" />
                              <img src={star_grey} alt="" />
                              <span style={{ color: "#1F1104" }}>
                                {transporter.rating}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="p-2">
                          <Link to="#">
                            {" "}
                            <img src={ic_options} alt="" />
                          </Link>
                        </div>
                      </div>
                    </Link>
                  );
                })}

              {/* Vehicle Lists */}

              {/* <div
                className="contract-rates mt-4 mb-4"
                style={{ width: "100%", overflow: "auto" }}
              >
                <h4 className="card-heading">VEHICLE LIST</h4>
                <table
                  className="table table-striped example dataList"
                  id="dataList"
                >
                  <thead>
                    <tr>
                      <td>
                        <label htmlFor="">Sr.No.</label>
                      </td>
                      <td>
                        <label htmlFor="">Owner Name</label>
                      </td>
                      <td>
                        <label htmlFor="">Vehicle Type</label>
                      </td>
                      <td>
                        <label htmlFor="">Model</label>
                      </td>
                      <td>
                        <label htmlFor="">Load Capacity</label>
                      </td>
                      <td>
                        <label htmlFor="">Plate No.</label>
                      </td>
                      <td>
                        <label htmlFor="">Driver Name</label>
                      </td>
                      <td>
                        <label htmlFor="">Nationality</label>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="Rowbgcolor ">
                      <td>
                        <p className="data m-0">1</p>
                      </td>
                      <td>
                        <p className="data m-0">Joshua Kimmich</p>
                      </td>
                      <td>
                        <p className="data m-0">Truck - Trailer</p>
                      </td>
                      <td>
                        <p className="data m-0">Eicher Pro 1110XP Refrig…</p>
                      </td>
                      <td>
                        <p className="data m-0">10 Quintals</p>
                      </td>
                      <td>
                        <p className="data m-0">TN AL 2021</p>
                      </td>
                      <td>
                        <p className="data m-0">Robert Lewandoski</p>
                      </td>
                      <td>
                        <p className="data m-0">India</p>
                      </td>
                      <td>
                        <p className="data m-0">
                          <img src={ic_options_menu} alt="" height="24" />
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="data m-0">1</p>
                      </td>
                      <td>
                        <p className="data m-0">Joshua Kimmich</p>
                      </td>
                      <td>
                        <p className="data m-0">Truck - Trailer</p>
                      </td>
                      <td>
                        <p className="data m-0">Eicher Pro 1110XP Refrig…</p>
                      </td>
                      <td>
                        <p className="data m-0">10 Quintals</p>
                      </td>
                      <td>
                        <p className="data m-0">TN AL 2021</p>
                      </td>
                      <td>
                        <p className="data m-0">Robert Lewandoski</p>
                      </td>
                      <td>
                        <p className="data m-0">India</p>
                      </td>
                      <td>
                        <p className="data m-0">
                          <img src={ic_options_menu} alt="" height="24" />
                        </p>
                      </td>
                    </tr>
                    <tr className="t-row">
                      <td>
                        <p className="data m-0">1</p>
                      </td>
                      <td>
                        <p className="data m-0">Joshua Kimmich</p>
                      </td>
                      <td>
                        <p className="data m-0">Truck - Trailer</p>
                      </td>
                      <td>
                        <p className="data m-0">Eicher Pro 1110XP Refrig…</p>
                      </td>
                      <td>
                        <p className="data m-0">10 Quintals</p>
                      </td>
                      <td>
                        <p className="data m-0">TN AL 2021</p>
                      </td>
                      <td>
                        <p className="data m-0">Robert Lewandoski</p>
                      </td>
                      <td>
                        <p className="data m-0">India</p>
                      </td>
                      <td>
                        <p className="data m-0">
                          <img src={ic_options_menu} alt="" height="24" />
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> */}

              {/* Popup Box */}

              {/* <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                id=""
              >
                <Modal.Header closeButton>
                  <h4 className="card-heading">Vehicle Details</h4>
                </Modal.Header>
                <div>
                  <hr />
                  <section className="vehicle-detail-section mb-0 common-body p-3">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="image">
                          <div
                            id="carouselExampleControls"
                            className="carousel slide"
                            data-bs-ride="carousel"
                          >
                            <div className="carousel-inner">
                              <div className="carousel-item active">
                                <img
                                  src={Skyscrapers}
                                  className="d-block "
                                  alt={"..."}
                                />
                              </div>
                              <div className="carousel-item">
                                <img
                                  src={Skyscrapers}
                                  className="d-block "
                                  alt="..."
                                />
                              </div>
                              <div className="carousel-item">
                                <img
                                  src={Skyscrapers}
                                  className="d-block "
                                  alt="..."
                                />
                              </div>
                            </div>
                            <button
                              className="carousel-control-prev"
                              type="button"
                              data-bs-target="#carouselExampleControls"
                              data-bs-slide="prev"
                            >
                              <span
                                className="carousel-control-prev-icon"
                                aria-hidden="true"
                              ></span>
                              <span className="visually-hidden">Previous</span>
                            </button>
                            <button
                              className="carousel-control-next"
                              type="button"
                              data-bs-target="#carouselExampleControls"
                              data-bs-slide="next"
                            >
                              <span
                                className="carousel-control-next-icon"
                                aria-hidden="true"
                              ></span>
                              <span className="visually-hidden">Next</span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="right-section">
                          <div className="right-top-content d-flex justify-content-between">
                            <div className="title-vehicle">
                              <h4 className="name-vehicle">
                                Eicher Pro 1110XP
                              </h4>
                            </div>
                            <div className="icon-dropdown dropdown">
                              <a
                                href=""
                                className=""
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <img src={ic_options_menu} alt="" height="24" />
                              </a>

                              <ul className="dropdown-menu">
                                <li>
                                  <button
                                    className="dropdown-item "
                                    type="button"
                                  >
                                    Edit{" "}
                                    <img
                                      src={dropdown_arrow_blue}
                                      className="arrow"
                                      alt=""
                                    />
                                  </button>
                                </li>
                                <li>
                                  <Link
                                    to="/ChangeDriver"
                                    className="text-decoration-none"
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasDriverAccount"
                                    aria-controls="offcanvasRightAccount"
                                  >
                                    <button
                                      className="dropdown-item "
                                      type="button"
                                    >
                                      Change Driver
                                    </button>
                                  </Link>
                                </li>
                                <li>
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                  >
                                    Block
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="bottom-content row mt-4">
                            <div className="col-lg-4 left item">
                              <label>Plate Number</label>
                              <p className="data">UI343546UF890</p>
                            </div>
                            <div className="col-lg-4 middel item">
                              <label>Vehicle Type</label>
                              <p className="data">Truck - Trailer</p>
                            </div>
                            <div className=" col-lg-4 right item">
                              <label>Year of Manufacture</label>
                              <p className="data">Reefer</p>
                            </div>
                          </div>
                          <div className="bottom-content row mt-3">
                            <div className="col-lg-4 left item">
                              <label>Chassis Number</label>
                              <p className="data">45678</p>
                            </div>
                            <div className="col-lg-4 middel item">
                              <label>Motor Number</label>
                              <p className="data">2345</p>
                            </div>
                            <div className="col-lg-4 right item">
                              <label>Gross Weight</label>
                              <p className="data">2345</p>
                            </div>
                          </div>
                          <div className="bottom-content row mt-3">
                            <div className="col-lg-4 left item">
                              <label>Vehicle Load Capacity</label>
                              <p className="data">10 Quintals</p>
                            </div>

                            <div className="col-lg-4 right item">
                              <label>Initial km</label>
                              <p className="data">1234</p>
                            </div>
                          </div>
                          <h5 className="card-heading">GPS Availability</h5>
                          <div className="bottom-content row mt-3">
                            <div className="col-lg-4 left item">
                              <label>Vendor Name</label>
                              <p className="data">Name</p>
                            </div>

                            <div className="col-lg-4 right item">
                              <label>Vendor Contact</label>
                              <p className="data">Contact</p>
                            </div>
                            <div className="col-lg-4 right item">
                              <label>Vendor Platform</label>
                              <p className="data">Platform</p>
                            </div>
                            <div className="col-lg-4 right item">
                              <label>Vendor Address</label>
                              <p className="data">Address</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <ChangeDriver />
                </div>
              </Modal> */}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ViewDetails;

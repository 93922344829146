// const BASE_URL = "http://abhay-logistics.redbytes.in/api/shipper/";
const CLIENT_BASE_URL = "https://abayadmin.abaylogistics.com/api/shipper/";
const ApiConfig = {
  // BASE_URL,
  CLIENT_BASE_URL,
  BASE_URL_FOR_IMAGES: "https://abayadmin.abaylogistics.com/",
  /* Transporter API url Start*/
  SHIPPER_LOGIN_API: CLIENT_BASE_URL + "login",
  SHIPPER_LOGOUT_API: CLIENT_BASE_URL + "logout",
  ADD_LOAD_API: CLIENT_BASE_URL + "load/add",
  DROP_DOWNS_API: CLIENT_BASE_URL + "load/dropdownlist",
  OFFER_LOAD_LIST: CLIENT_BASE_URL + "load/list",
  OFFER_LOAD_DETAILS: CLIENT_BASE_URL + "load/details",
  VEHICLE_OFFER_LIST: CLIENT_BASE_URL + "vehicleoffer/directorders",
  ACCEPT_DIRECT_ORDER: CLIENT_BASE_URL + "vehicleoffer/accept",
  REJECT_DIRECT_ORDER: CLIENT_BASE_URL + "vehicleoffer/reject",
  DIRECT_ORDER_CONFIRMATION_LIST: CLIENT_BASE_URL + "orderconfirmation",
  ONLINE_ORDER_CONFIRMATION_LIST: CLIENT_BASE_URL + "orderconfirmation/online",
  ORDER_CONFIRMATION_DETAILS:
    CLIENT_BASE_URL + "orderconfirmation/vehicledetails",
  TRANSPOTER_LIST: CLIENT_BASE_URL + "vehicleoffer/transporterlist",
  AUCTION_DETAILS: CLIENT_BASE_URL + "vehicleoffer/auctiondetails",
  DASHBOARD_DETAILS: CLIENT_BASE_URL + "dashboard",
  PROFILE: CLIENT_BASE_URL + "profile",
  VEHICLE_LIST: CLIENT_BASE_URL + "vehicleoffer/vehicledetails",
  REPORT_TYPES: CLIENT_BASE_URL + "report/types",
  REPORT_ADD: CLIENT_BASE_URL + "report/add",
  ONLINE_VEHICLE_OFFER_LIST: CLIENT_BASE_URL + "vehicleoffer/onlineorders",
  ONGOING_FRIGHT: CLIENT_BASE_URL + "freights/ongoing",
  UPCOMING_FRIGHT: CLIENT_BASE_URL + "freights/upcoming",
  COMPLETED_FRIGHT: CLIENT_BASE_URL + "freights/completed",
  LIVE_TRANSPORTER_LIST: CLIENT_BASE_URL + "livetrack/transporterlist",
  NOTIFICATION: CLIENT_BASE_URL + "notification",
  DELETE_NOTIFICATION: CLIENT_BASE_URL + "notification/bulk_delete",

  TRANSPORTER_CONTACT_US_API: CLIENT_BASE_URL + "contactus",
  AddVehicleDropdownlist: CLIENT_BASE_URL + "vehicle/dropdownlist",
  ADDOWNER: CLIENT_BASE_URL + "owner/add",
  AddVehicle: CLIENT_BASE_URL + "vehicle/add",
  OWNER_LIST: CLIENT_BASE_URL + "owner/list",
  DRIVER_LIST: CLIENT_BASE_URL + "driver/list",
  // VEHICLE_LIST: CLIENT_BASE_URL + "vehicle/list",
  DRIVER_DETAILS: CLIENT_BASE_URL + "driver/details",
  VEHICLE_DETAILS: CLIENT_BASE_URL + "vehicle/details",
  CHANGE_DRIVER_VEHICLE: CLIENT_BASE_URL + "driver/changedriver_vehicle",
  ADD_DRIVER: CLIENT_BASE_URL + "driver/add",
  ONLINE_AUCTION_OFFER_GOODS: CLIENT_BASE_URL + "vehiclerequest/onlineorders",
  // ONGOING_FRIGHT: CLIENT_BASE_URL + "freights/ongoing",
  UPCOMMING_FRIGHT: CLIENT_BASE_URL + "freights/upcoming",
  COMPLETED_FRIGHT: CLIENT_BASE_URL + "freights/completed",
  DRIRECT_ORDER_OFFER_GOODS: CLIENT_BASE_URL + "vehiclerequest/directorders",
  GOODS_DETAILS: CLIENT_BASE_URL + "load/details",
  DIRECT_ORDERS_OFFERED_VEHICLES: CLIENT_BASE_URL + "vehicleoffer/directorders",
  DIRECT_ORDERS_ORDER_CONFIRMATION_: CLIENT_BASE_URL + "orderconfirmation",
  VIEWSPECIFICATION: CLIENT_BASE_URL + "vehiclerequest/viewspecification",
  BLOCK_VEHICLE: CLIENT_BASE_URL + "vehicle/block",
  BLOCK_DRIVER: CLIENT_BASE_URL + "driver/block",
  DIRECT_ORDER_OFFER_GOODS_VEHICLE_REJECT:
    CLIENT_BASE_URL + "vehiclerequest/reject",
  DRIRECT_ORDER_OFFER_GOODS_VEHICLE_ACCEPT:
    CLIENT_BASE_URL + "vehiclerequest/accept",
  DRIRECT_ORDER_OFFER_VEHICLES_VEHICLE_DETAILS:
    CLIENT_BASE_URL + "vehicleoffer/vehicledetails",
  ONLINE_AUCTION_OFFERGOODS_MAKE_BID: CLIENT_BASE_URL + "bid/add",
  PROFILE: CLIENT_BASE_URL + "profile",
  DASHBOARD: CLIENT_BASE_URL + "dashboard",
  NOTIFICATION: CLIENT_BASE_URL + "notification",
  DELETE_NOTIFICATION: CLIENT_BASE_URL + "notification/bulk_delete",
  REGISTER_DETAILS: CLIENT_BASE_URL + "register",
  CURRENCY_DROPDOWN: CLIENT_BASE_URL + "dropdown/currency",
  ADD_SHIPPER_DROPDOWN: CLIENT_BASE_URL + "dropdown",
  FORGET_PASSWORD: CLIENT_BASE_URL + "forgotpassword/",
  RESET_PASSWORD: CLIENT_BASE_URL + "resetpassword",
  RATE_CHART_LIST: CLIENT_BASE_URL + "tariffchart",
  TARRIF_EXPORT_LIST: CLIENT_BASE_URL + "tariffexport",
  TARRIF_IMPORT_LIST: CLIENT_BASE_URL + "tariffimport",
  CONTAINER_TYPES: CLIENT_BASE_URL + "dropdown/containertype",
  SHIPPER_DETAILS: CLIENT_BASE_URL + "details",
  EDITSHIPPER : CLIENT_BASE_URL + "register/edit",
  CHANGE_PASSWORD: CLIENT_BASE_URL + "changepassword/",
  /* Transporter API url End*/
};
export default ApiConfig;
